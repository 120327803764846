import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectFilterLookups (Vue) {
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    let apiCalls = []
    apiCalls.push({ name: 'selectTcliente', method: 'tcliente.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectZona', method: 'zona.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectAgenteComercial', method: 'agenteComercial.select', params: { filter: apiFilterEstadoActivo } })
    // TSECTOR no tiene campo estado
    apiCalls.push({ name: 'selectTsector', method: 'tsector.select' })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectClientePotencial (Vue, filter, search, sorter, page, mapBounds, pageSize) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGT('estado', 0)
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.clientePotencial.searchFilter(search))
    }
    if (filter.clienteNombre.value) {
      apiFilter.addILike(filter.clienteNombre.field, filter.clienteNombre.value)
    }
    if (filter.razonSocial.value) {
      apiFilter.addILike(filter.razonSocial.field, filter.razonSocial.value)
    }
    if (filter.cif.value) {
      apiFilter.addILike(filter.cif.field, filter.cif.value)
    }
    if (filter.calle.value) {
      apiFilter.addILike(filter.calle.field, filter.calle.value)
    }
    if (filter.cp.value) {
      apiFilter.addILike(filter.cp.field, filter.cp.value)
    }
    if (filter.localidad.value) {
      apiFilter.addILike(filter.localidad.field, filter.localidad.value)
    }
    if (filter.tcliente.value && filter.tcliente.value.length > 0) {
      apiFilter.addIn(filter.tcliente.field, filter.tcliente.value)
    }
    if (filter.tsector.value && filter.tsector.value.length > 0) {
      apiFilter.addIn(filter.tsector.field, filter.tsector.value)
    }
    if (filter.agenteComercial.value && filter.agenteComercial.value.length > 0) {
      apiFilter.addIn(filter.agenteComercial.field, filter.agenteComercial.value)
    }
    if (filter.zona.value && filter.zona.value.length > 0) {
      apiFilter.addIn(filter.zona.field, filter.zona.value)
    }
    if (filter.prospecto.value) {
      apiFilter.addExact(filter.prospecto.field, filter.prospecto.value)
    }
    if (mapBounds) {
      apiFilter.addGT('latitud', mapBounds.swLat)
      apiFilter.addLT('latitud', mapBounds.neLat)
      apiFilter.addGT('longitud', mapBounds.swLng)
      apiFilter.addLT('longitud', mapBounds.neLng)
    }
    const resp = await Vue.$api.call('clientePotencial.select', {
      page,
      filter: apiFilter,
      sorter,
      page_size: pageSize,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectClientePotencialRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idcliente_potencial', pks)
    const resp = await Vue.$api.call('clientePotencial.select', {
      filter: apiFilter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
